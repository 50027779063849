<template>
  <v-col class="d-flex flex-column flex-grow-1">
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr
            v-for="(category, i) in categories"
            :key="`category-${i}`"
          >
            <td class="icon-col">
              <v-icon
                :color="'#' + category.color"
              >
                {{ 'mdi-' + category.icon }}
              </v-icon>
            </td>
            <td>{{ category.name }}</td>
            <td class="icon-col">
              <o-action-menu
                :actions="[
                  {
                    icon: 'mdi-pencil',
                    label: 'Modifier',
                    listeners: {
                      click: () => $emit('update-category', category.id)
                    }
                  },
                  {
                    icon: 'mdi-delete',
                    label: 'Supprimer',
                    color: 'red',
                    confirm: {
                      callback: () => deleteCategory(category.id),
                      title: 'messages.views.admin.components.information.window.categoriesWindow.modal.deleteCategory.title',
                      text: 'messages.views.admin.components.information.window.categoriesWindow.modal.deleteCategory.text',
                      color: 'red'
                    }
                  },
                ]"
              />
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-spacer />

    <div class="d-flex justify-end">
      <v-btn
        color="secondary"
        fab
        @click="() => $emit('create-category')"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </v-col>
</template>

<script>
  export default {
    apollo: {
      categories: {
        query: require('@gql/views/admin/components/information/window/categories/getCategories.gql'),
        client: 'information',
        fetchPolicy: 'no-cache',
        result (res, key) {
          this.loading = res.loading;
        },
        update (data) {
          return data.getCategories;
        },
      },
    },

    methods: {
      deleteCategory (categoryId) {
        this.loading = true;
        const index = this.categories.findIndex((category) => category.id === categoryId);
        this.categories.splice(index, 1);

        this.$apollo.mutate({
          mutation: require('@gql/mutations/category/deleteCategory.gql'),
          client: 'information',
          fetchPolicy: 'no-cache',
          variables: {
            id: categoryId,
          },
        }).catch((e) => {
          this.$flash(null, 'error');
        }).finally(() => {
          this.loading = false;
        });
      },
    },
  };
</script>
