<template>
  <category-form
    @submit="$emit('created')"
  />
</template>

<script>
  export default {
    components: {
      CategoryForm: () => import('@/views/admin/components/information/window/CategoryForm'),
    },
  };
</script>
