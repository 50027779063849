<template>
  <div
    class="fill-height"
  >
    <v-btn
      color="primary"
      class="ma-2"
      style="z-index: 1; opacity: 0.8;"
      absolute
      :to="{ name: 'public_dashboard' }"
      target="_blank"
    >
      <v-icon
        small
        class="mr-2"
      >
        mdi-eye
      </v-icon>
      {{ $t('messages.views.admin.information.publicAccess') }}
    </v-btn>

    <v-row
      no-gutters
      class="fill-height"
    >
      <v-col
        cols="8"
        class="fill-height"
      >
        <slot name="map" />
      </v-col>
      <v-col
        cols="4"
        class="fill-height"
      >
        <v-card
          tile
          class="fill-height d-flex flex-column justify-start"
        >
          <v-toolbar
            flat
            class="flex-grow-0"
          >
            <v-btn
              v-if="tab !== 'items'"
              icon
              light
              @click="previous"
            >
              <v-icon>
                mdi-arrow-left
              </v-icon>
            </v-btn>

            <v-toolbar-title class="primary--text">
              {{ $t('messages.views.admin.information.windows.' + tab) }}
            </v-toolbar-title>

            <v-spacer />

            <v-btn
              v-if="tab === 'items'"
              color="secondary"
              outlined
              @click="tab = 'categories'"
            >
              <v-icon class="mr-2">
                mdi-group
              </v-icon>
              {{ $t('messages.views.admin.information.categories') }}
            </v-btn>
          </v-toolbar>
          <perfect-scrollbar class="flex-grow-1 d-flex flex-column">
            <v-slide-x-transition
              mode="out-in"
            >
              <component
                :is="selectedWindow.component"
                v-bind="selectedWindow.attrs"
                v-on="selectedWindow.listeners"
              />
            </v-slide-x-transition>
          </perfect-scrollbar>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import ItemsWindow from '@/views/admin/components/information/window/ItemsWindow';
  import CreateCategoryWindow from '@/views/admin/components/information/window/CreateCategoryWindow';
  import UpdateCategoryWindow from '@/views/admin/components/information/window/UpdateCategoryWindow';
  import CreateItemWindow from '@/views/admin/components/information/window/CreateItemWindow';
  import UpdateItemWindow from '@/views/admin/components/information/window/UpdateItemWindow';
  import CategoriesWindow from '@/views/admin/components/information/window/CategoriesWindow';

  export default {
    components: {
      ItemsWindow: () => import('@/views/admin/components/information/window/ItemsWindow'),
    },

    data: function () {
      return {
        loading: true,
        tab: 'items',
        windows: {
          items: {
            component: ItemsWindow,
            attrs: {},
            listeners: {
              'create-item': () => {
                this.tab = 'createItem';
              },
              'update-item': (itemId) => {
                this.windows.updateItem.attrs.id = itemId;
                this.tab = 'updateItem';
              },
            },
          },
          categories: {
            component: CategoriesWindow,
            attrs: {},
            listeners: {
              'create-category': () => {
                this.tab = 'createCategory';
              },
              'update-category': (categoryId) => {
                this.windows.updateCategory.attrs.id = categoryId;
                this.tab = 'updateCategory';
              },
            },
          },
          createCategory: {
            component: CreateCategoryWindow,
            attrs: {},
            listeners: {
              created: (categoryId) => {
                this.previous();
              },
            },
          },
          updateCategory: {
            component: UpdateCategoryWindow,
            attrs: {
              id: null,
            },
            listeners: {
              updated: (categoryId) => {
                this.previous();
              },
            },
          },
          createItem: {
            component: CreateItemWindow,
            attrs: {},
            listeners: {
              created: (itemId) => {
                this.previous();
              },
            },
          },
          updateItem: {
            component: UpdateItemWindow,
            attrs: {
              id: null,
            },
            listeners: {
              updated: (itemId) => {
                this.previous();
              },
            },
          },
        },
        breadcrumb: {
          categories: 'items',
          createCategory: 'categories',
          updateCategory: 'categories',
          createItem: 'items',
          updateItem: 'items',
        },
      };
    },

    computed: {
      selectedWindow () {
        return this.windows[this.tab];
      },
    },

    mounted () {
      this.$store.commit('map/reset');
    },

    methods: {
      previous () {
        this.tab = this.breadcrumb[this.tab];
      },
    },
  };
</script>
