<template>
  <item-form
    :id="id"
    @submit="$emit('updated')"
  />
</template>

<script>
  export default {
    components: {
      ItemForm: () => import('@/views/admin/components/information/window/ItemForm'),
    },

    props: {
      id: {
        type: [String, Number],
        required: true,
      },
    },
  };
</script>
