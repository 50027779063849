var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"d-flex flex-column flex-grow-1"},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.categories),function(category,i){return _c('tr',{key:("category-" + i)},[_c('td',{staticClass:"icon-col"},[_c('v-icon',{attrs:{"color":'#' + category.color}},[_vm._v(" "+_vm._s('mdi-' + category.icon)+" ")])],1),_c('td',[_vm._v(_vm._s(category.name))]),_c('td',{staticClass:"icon-col"},[_c('o-action-menu',{attrs:{"actions":[
                {
                  icon: 'mdi-pencil',
                  label: 'Modifier',
                  listeners: {
                    click: function () { return _vm.$emit('update-category', category.id); }
                  }
                },
                {
                  icon: 'mdi-delete',
                  label: 'Supprimer',
                  color: 'red',
                  confirm: {
                    callback: function () { return _vm.deleteCategory(category.id); },
                    title: 'messages.views.admin.components.information.window.categoriesWindow.modal.deleteCategory.title',
                    text: 'messages.views.admin.components.information.window.categoriesWindow.modal.deleteCategory.text',
                    color: 'red'
                  }
                } ]}})],1)])}),0)]},proxy:true}])}),_c('v-spacer'),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"secondary","fab":""},on:{"click":function () { return _vm.$emit('create-category'); }}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }