<template>
  <v-col class="d-flex flex-column flex-grow-1">
    <v-row no-gutters>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="itemCount"
          :loading="loading"
          dense
          disable-sort
          hide-default-footer
        >
          <template v-slot:item.icon="{ item }">
            <v-icon
              :color="'#' + (item.color ? item.color : getCategory(item.category).color)"
            >
              {{ 'mdi-' + (item.icon ? item.icon : getCategory(item.category).icon) }}
            </v-icon>
          </template>

          <template v-slot:item.actions="{ item }">
            <o-action-menu
              :actions="[
                {
                  icon: 'mdi-pencil',
                  label: 'Modifier',
                  listeners: {
                    click: () => $emit('update-item', item.id)
                  }
                },
                {
                  icon: 'mdi-delete',
                  label: 'Supprimer',
                  color: 'red',
                  confirm: {
                    callback: () => deleteItem(item.id),
                    title: 'messages.views.admin.components.information.window.itemsWindow.modal.deleteItem.title',
                    text: 'messages.views.admin.components.information.window.itemsWindow.modal.deleteItem.text',
                    color: 'red'
                  }
                },
              ]"
            />
          </template>

          <template v-slot:footer>
            <o-data-footer
              :has-previous-page="!!data && !!data.searchItems && data.searchItems.pageInfo.hasPreviousPage"
              :has-next-page="!!data && !!data.searchItems && data.searchItems.pageInfo.hasNextPage"
              @previous="previous"
              @next="next"
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-spacer />

    <div class="d-flex justify-end">
      <v-btn
        color="secondary"
        fab
        @click="() => $emit('create-item')"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </v-col>
</template>

<script>
  const DEFAULT_ITEMS_PER_PAGE = 10;

  export default {
    apollo: {
      data: {
        query: require('@gql/views/admin/components/information/window/items/getData.gql'),
        client: 'information',
        fetchPolicy: 'no-cache',
        variables: function () {
          return {
            first: this.pagination.first,
            last: this.pagination.last,
            before: this.pagination.before,
            after: this.pagination.after,
          };
        },
        result (res, key) {
          this.loading = res.loading;
        },
        update (data) {
          this.categories = data.getCategories;
          this.items = data.searchItems.edges.map(edge => edge.node);
          this.itemCount = data.searchItems.totalCount;
        },
      },
    },

    data: function () {
      return {
        loading: true,
        itemCount: 0,
        categories: [],
        items: [],
        pagination: {
          itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
          first: DEFAULT_ITEMS_PER_PAGE,
          last: null,
          before: null,
          after: null,
        },
        headers: [
          {
            value: 'icon',
            align: 'center',
            cellClass: 'icon-col',
          },
          {
            text: this.$t('labels.item.name'),
            value: 'name',
            align: 'left',
          },
          {
            value: 'actions',
            align: 'center',
            cellClass: 'icon-col',
          },
        ],
      };
    },

    watch: {
      pagination: {
        handler () {
          this.loading = true;
          this.$apollo.queries.data.refresh();
        },
        deep: true,
      },
    },

    methods: {
      resetPagination () {
        this.pagination.first = DEFAULT_ITEMS_PER_PAGE;
        this.pagination.after = null;
        this.pagination.last = null;
        this.pagination.before = null;
      },

      previous () {
        this.pagination.first = null;
        this.pagination.after = null;
        this.pagination.last = this.pagination.itemsPerPage;
        this.pagination.before = this.data.searchItems.edges[this.data.searchItems.edges.length - 1].cursor;
      },

      next () {
        this.pagination.first = this.pagination.itemsPerPage;
        this.pagination.after = this.data.searchItems.edges[this.data.searchItems.edges.length - 1].cursor;
        this.pagination.last = null;
        this.pagination.before = null;
      },

      getCategory (id) {
        return this.categories.find((category) => category.id === id);
      },

      deleteItem (itemId) {
        this.loading = true;

        this.$apollo.mutate({
          mutation: require('@gql/mutations/item/deleteItem.gql'),
          client: 'information',
          fetchPolicy: 'no-cache',
          variables: {
            id: itemId,
          },
        }).then((e) => {
          this.$apollo.queries.data.refresh();
        }).catch((e) => {
          this.$flash(null, 'error');
        }).finally(() => {
          this.loading = false;
        });
      },
    },
  };
</script>
