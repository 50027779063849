var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"d-flex flex-column flex-grow-1"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.itemCount,"loading":_vm.loading,"dense":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":'#' + (item.color ? item.color : _vm.getCategory(item.category).color)}},[_vm._v(" "+_vm._s('mdi-' + (item.icon ? item.icon : _vm.getCategory(item.category).icon))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('o-action-menu',{attrs:{"actions":[
              {
                icon: 'mdi-pencil',
                label: 'Modifier',
                listeners: {
                  click: function () { return _vm.$emit('update-item', item.id); }
                }
              },
              {
                icon: 'mdi-delete',
                label: 'Supprimer',
                color: 'red',
                confirm: {
                  callback: function () { return _vm.deleteItem(item.id); },
                  title: 'messages.views.admin.components.information.window.itemsWindow.modal.deleteItem.title',
                  text: 'messages.views.admin.components.information.window.itemsWindow.modal.deleteItem.text',
                  color: 'red'
                }
              } ]}})]}},{key:"footer",fn:function(){return [_c('o-data-footer',{attrs:{"has-previous-page":!!_vm.data && !!_vm.data.searchItems && _vm.data.searchItems.pageInfo.hasPreviousPage,"has-next-page":!!_vm.data && !!_vm.data.searchItems && _vm.data.searchItems.pageInfo.hasNextPage},on:{"previous":_vm.previous,"next":_vm.next}})]},proxy:true}])})],1)],1),_c('v-spacer'),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"secondary","fab":""},on:{"click":function () { return _vm.$emit('create-item'); }}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }